import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

const Faq = ({ items }) => {
	const { t } = useI18next();

	return (
		<>
			<div className="accordion accordion-flush" id="accordion">
				{items.map((item, index) => {
					return (
						<div className="accordion-item" key={index}>
							<h2 className="accordion-header" id="headingTwo">
								<button
									className="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target={`#collapse-${index}`}
									aria-expanded="false"
									aria-controls={`collapse-${index}`}
								>
									{t(`${item.node.translation_key}_title`)}
								</button>
							</h2>
							<div
								id={`collapse-${index}`}
								className="accordion-collapse collapse"
								aria-labelledby="headingTwo"
								data-bs-parent="#accordion"
							>
								<div className="accordion-body">
									{t(`${item.node.translation_key}_description`)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

Faq.propTypes = {
	items: PropTypes.array,
};

export default Faq;
