import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const isSafari = () => {
	const user_agent = navigator.userAgent.toLowerCase();
	return user_agent.indexOf('safari') > -1 && user_agent.indexOf('chrome') < 0;
};

const CardVideo = ({ video_mp4, video_webm }) => {
	const reference = useRef(null);

	useEffect(() => {
		if (isSafari() && reference.current) {
			const player = reference.current;

			if (player) {
				player.autoplay = true;
				player.controls = false;
				player.muted = true;
				player.playsinline = true;
				player.setAttribute('muted', '');

				setTimeout(() => {
					const promise = player.play();
					if (promise.then) {
						promise
							.then(() => {})
							.catch(() => {
								reference.current.style.display = 'none';
							});
					}
				}, 0);
			}
		}
	}, []);

	return (
		<>
			<section className="video">
				<div className="video__container o-hidden mt-3 mt-md-5">
					<div className="video__container-responsive">
						<video
							title="PCR-test götgatan video"
							autoPlay={true}
							className="video__container-video"
							loop={true}
							muted={true}
							playsInline={true}
							ref={reference}
						>
							{video_mp4 && <source src={video_mp4} type="video/mp4" />}
							{video_webm && <source src={video_webm} type="video/webm" />}
							Your browser does not support HTML5 video.
						</video>
						<canvas className="video__container--canvas d-none"></canvas>
						<div className="video__container--canvas-cover">
							<div className="container-fluid h-100">
								<div className="row h-100 justify-content-start align-items-center"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

CardVideo.propTypes = {
	video_mp4: PropTypes.string,
	video_webm: PropTypes.string,
};

export default CardVideo;
