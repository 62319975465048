import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import CtaLink from './cta-link';

const PricesTable = ({ types }) => {
	const { t } = useI18next();

	return (
		<>
			<table className="table my-4">
				<tbody>
					{(!types || (types && types.includes('antigen'))) && (
						<>
							<tr>
								<td>
									<span className="">{t('component_prices:t1')}</span>
									<p className="x-small text-muted">
										{t('component_prices:t2')}
									</p>
								</td>
								<td>
									<Trans
										i18nKey="component_prices:t3"
										components={{ s: <s /> }}
									/>
								</td>
							</tr>
						</>
					)}
					{(!types || (types && types.includes('pcr'))) && (
						<>
							<tr>
								<td>
									{t('component_prices:t4')}&#160;
									<p className="x-small text-muted">
										{t('component_prices:t5')}
									</p>
								</td>
								<td>
									<Trans
										i18nKey="component_prices:t6"
										components={{ s: <s /> }}
									/>
								</td>
							</tr>

							<tr>
								<td>
									{t('component_prices:t10')}&#160;
									<p className="x-small text-muted">
										{t('component_prices:t11')}
									</p>
								</td>
								<td>
									<Trans
										i18nKey="component_prices:t12"
										components={{ s: <s /> }}
									/>
								</td>
							</tr>
						</>
					)}
				</tbody>
			</table>
			<CtaLink label={t('component_prices:t13')} linkUrl="/book" />
		</>
	);
};

PricesTable.propTypes = {
	types: PropTypes.array,
};

export default PricesTable;
