import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import PCRThroatImg from '../../images/framework/icon-pcr.svg';
import CtaLink from './cta-link';
import WaitTooltip from '../information/wait_tooltip';

const ThroatTest = ({ direction }) => {
	const { t } = useI18next();

	return (
		<>
			<section id="throat-test">
				<div className="container">
					<div
						className={`row ${
							direction === 'right'
								? 'justify-content-end'
								: 'justify-content-start'
						}`}
					>
						<div className="col-md-auto">
							<div className="info-img-container">
								<img
									alt=""
									className="img-fluid mb-0 mb-lg-0"
									src={PCRThroatImg}
								/>
							</div>
						</div>
						<div className="col-md-7">
							<h2 className="h3 mt-1">{t('component_throat_test:t1')}</h2>
							<p className="small text-secondary">
								<Trans
									i18nKey="component_throat_test:t2"
									components={{ WaitTooltip: <WaitTooltip /> }}
								/>
							</p>
							<CtaLink label={t('component_throat_test:t3')} linkUrl="/book" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

ThroatTest.propTypes = {
	direction: PropTypes.string,
};

export default ThroatTest;
