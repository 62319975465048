import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Trustpilot from '../components/script/trustpilot';

import covidGif from '../images/framework/analyze.webp';
import HeroCell from '../images/framework/cell.svg';
import HeroCRP from '../images/framework/crp-gradient.svg';
import Phone from '../images/framework/phone.png';
import Disclaimer from '../components/framework/disclaimer';

import CardAntigen from '../components/framework/card-antigen';
import CardPCR from '../components/framework/cardPCR';

import CtaLink from '../components/framework/cta-link';
import ServiceList from '../components/framework/service-list';
import Prices from '../components/framework/prices';
import CardVideo from '../components/framework/card-video';
import ThroatTest from '../components/framework/throat-test';
import SaliveTest from '../components/framework/salive-test';
import DeliveryTime from '../components/framework/delivery-time';
import Location from '../components/framework/location';
import TravelInfo from '../components/framework/travel-info';
import Faq from '../components/framework/faq';
import Framework from '../components/framework';
import Seo from '../components/framework/seo';

const IndexPage = ({ data, location }) => {
	const support_items = data.allSupport.edges;
	const { t } = useTranslation('index');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<section id="intro">
				<div className="container-fluid container-intro">
					<div className="container-fluid container-intro bg-hero-gradient" />
					<img alt="Background" className="hero-cell" src={HeroCell} />
					<img alt="Background" className="hero-crp d-none" src={HeroCRP} />
					<div className="row h-100 justify-content-start align-items-center">
						<div className="col-xxl-5 col-md-6 pe-xxl-5 pt-5">
							<div className="row">
								<div className="col-auto"></div>
							</div>

							<h1 className="title-super">{t('t1')}</h1>
							<p className="text-secondary">{t('t2')}</p>
							<CtaLink label={t('t3')} linkUrl="/book" />
						</div>
						<div className="col-md-6 col-xxl-7">
							<CardVideo
								video_mp4="https://cdn.testmottagningen.se/videos/zample-video.mp4"
								video_webm="https://cdn.testmottagningen.se/videos/zample-video.webm"
							/>
						</div>
					</div>
				</div>
			</section>
			<section id="products">
				<div className="container-fluid mt-3">
					<ServiceList />
				</div>
			</section>
			<section id="info">
				<div className="container-fluid mt-lg-5 mb-0">
					<div className="row justify-content-center align-items-center">
						<div className="col-xl-6 order-2 order-xl-1">
							<img
								alt="PCR och antigentest med reseintyg"
								className="img-fluid mb-0 mb-lg-0"
								src={Phone}
							/>
						</div>
						<div className="col-xl-6 order-1 order-xl-2">
							<h2 className="h3 mt-4 mb-2">{t('t8')}</h2>
							<div className="row mt-1 mb-2 justify-content-center small">
								<div className="col-12 text-muted">{t('t9')}</div>
								<div className="col-12 text-muted">{t('t10')}</div>
								<div className="col-12 text-muted">{t('t11')}</div>
								<div className="col-12 text-muted">{t('t12')}</div>
								<div className="col-12 text-muted">{t('t13')}</div>
								<div className="col-12 text-muted">{t('t14')}</div>
								<div className="col-12 text-muted">{t('t15')}</div>
							</div>
							<div className="x-small mb-0 pb-0 mb-md-3 pb-lg-3">
								<TravelInfo />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="delivery-time">
				<div className="container-fluid bg-primary-gradient2 py-5 mb-lg-5">
				<div className="container">
					
							<DeliveryTime />
					
					</div>
				</div>
			</section>
			<div className="my-lg-5 my-3">
				<ThroatTest />
			</div>
			<div className="my-lg-5 my-3">
				<SaliveTest direction="right" />
			</div>
			<section className="faq">
				<div className="container mt-lg-5 pt-5">
					<h2 className="h2 mb-3 mb-lg-4 h4">{t('t4')}</h2>
					<p className="small">{t('t5')}</p>
					<Faq items={support_items} />
					<div className="mt-3 mt-lg-5">
						<Disclaimer />
					</div>
				</div>
			</section>

			<section id="location">
				<div className="container-fluid px-0 mt-5 py-5 ">
					<div className="container">
						<h2 className="mb-3 h4">{t('t16')}</h2>
						<Location />
					</div>
				</div>
			</section>
		</Framework>
	);
};

IndexPage.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
	query {
		allSupport {
			edges {
				node {
					translation_key
				}
			}
		}

		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
