import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

const WaitTooltip = () => {
	const { t } = useI18next();

	return (
		<span
			data-bs-placement="top"
			data-bs-toggle="tooltip"
			title={t('component_wait_tooltip:t1')}
		>
			*
		</span>
	);
};

export default WaitTooltip;
