import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

const TravelInfo = () => {
	return (
		<>
			<p className="">
				<Trans i18nKey="component_travel_info:t1" components={{ a: <a /> }} />
			</p>
		</>
	);
};

export default TravelInfo;
