import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import antigenImg from '../../images/framework/icon-antigen.svg';
import CtaLink from './cta-link';

const ThroatTest = ({ direction }) => {
	const { t } = useI18next();

	return (
		<>
			<section id="throat-test">
				<div className="container">
					<div
						className={`row ${
							direction === 'right'
								? 'justify-content-end'
								: 'justify-content-start'
						}`}
					>
						<div className="col-md-auto">
							<div className="info-img-container mb-1 mb-md-0">
								<img
									alt=""
									className="img-fluid mb-0 mb-lg-0"
									src={antigenImg}
								/>
							</div>
						</div>
						<div className="col-md-7">
							<h2 className="h3 mt-1">{t('component_saliva_test:t1')}</h2>
							<p className="small text-secondary">
								{t('component_saliva_test:t2')}
							</p>
							<CtaLink label={t('component_saliva_test:t3')} linkUrl="/book" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

ThroatTest.propTypes = {
	direction: PropTypes.string,
};

export default ThroatTest;
