import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { ClockHistory } from 'react-bootstrap-icons';
import WaitTooltip from '../information/wait_tooltip';

const Disclaimer = () => {
	const { t } = useI18next();

	return (
		<>
			<div className="my-3">
				<h2 className="h1 text-primary">
					{t('component_delivery_time:t1')}
					<WaitTooltip />
				</h2>
				<p className="">{t('component_delivery_time:t2')}</p>
				<p className="small text-muted">{t('component_delivery_time:t3')}</p>
			</div>
		</>
	);
};

export default Disclaimer;
